import { toast } from '@/common/util';
import baseApi from './base.api';
import BaseService from './base.service';
export const certificateService = {
    ...baseApi,
    resource: 'certificado',
    getById: (id) => {
        return certificateService.get(id)
            .then(({ success, data }) => ({
            success,
            data: {
                id: data.id,
                name: data.nome,
                type: data.tipo === 'Curso' ? 'course' : 'trail',
                active: data.active,
                createdAt: data.createdAt,
                structure: JSON.parse(data.jsonCertificado)
            }
        }));
    },
    save: (localData) => {
        //Usa dicionário para converter os dados "local" para "remoto"
        const normalized = {
            nome: localData.name,
            tipo: localData.type === 'course' ? 'Curso' : 'Formacao',
            jsonCertificado: JSON.stringify(localData.structure)
        };
        const postDataRemote = new FormData();
        const keys = Object.keys(normalized);
        for (let i = 0; i < keys.length; i++)
            postDataRemote.append(keys[i], normalized[keys[i]]);
        const headers = { 'Content-Type': 'multipart/form-data' };
        // Se for novo certificado, remove o atributo id
        if (!localData.id) {
            return certificateService.post('', postDataRemote, { headers });
        }
        return certificateService.put(localData.id, postDataRemote, { headers });
    },
    getCertData(id, courseOrTrailId) {
        return certificateService
            .get(`Imprimir/${id}/${courseOrTrailId}`)
            .then(({ success, data }) => ({
            success,
            data: {
                ...data,
                course: data.curso?.itensDoCurso?.map(item => ({
                    name: item.midia?.nome || item.avaliacao?.titulo,
                    duration: item.midia?.duracao || null,
                    type: item.tipo
                })) || [],
                trail: data.formacao?.etapas?.map(step => ({
                    name: step.nome,
                    duration: step?.duracao,
                    items: step?.itens?.map(item => ({
                        name: item.nome,
                        duration: item?.duracao,
                        type: item.tipo,
                        items: item?.itens?.map(subItem => ({
                            name: subItem.nome,
                            duration: subItem.duracao
                        })) || []
                    })) || []
                })) || []
            }
        }));
    },
    /**
     * Valida o código do certificado (credencial)
     */
    validateCredential(code) {
        return certificateService
            .get(`validate/${code}/credential`)
            .then(({ success, data }) => {
            if (!success)
                return;
            const replaceData = {
                '#TITULO#': data.curso?.titulo || data.formacao?.titulo || '',
                '#NOME#': data.usuarioNome || '',
                '#CPF': data.usuarioCpf || '',
                '#CARGO#': data.cargoNome || '',
                '#DT_CONCLUSAO#': data.dataEmissao ? new Date(data.dataEmissao) : null,
                '#CARGA_HORARIA#': data.cargaHoraria || 0,
                '#DT_INSCRICAO#': (data.curso?.dataInscricao || data.formacao?.dataInscricao) ? new Date(data.curso?.dataInscricao || data.formacao?.dataInscricao) : null
            };
            const structure = JSON.parse(data.certificado.jsonCertificado);
            // Substitui os textos de cada layer de tipo 'text'
            structure.layers = structure.layers.map((layer) => {
                if (layer.type !== 'text')
                    return layer;
                let replacedText = layer.text.content;
                for (const tag in replaceData) {
                    if (Object.prototype.hasOwnProperty.call(replaceData, tag)) {
                        replacedText = replacedText.replace(new RegExp(tag, 'g'), replaceData[tag]?.toString() || '');
                    }
                }
                return {
                    ...layer,
                    text: {
                        ...layer.text,
                        content: replacedText
                    }
                };
            });
            // Substitui as camadas do tipo 'qrcode' pelo link de validação
            structure.layers = structure.layers.map((layer) => {
                if (layer.type !== 'object' || layer.object?.type !== 'qrcode')
                    return layer;
                return {
                    ...layer,
                    object: {
                        ...layer.object,
                        url: data.linkValidacao
                    }
                };
            });
            return structure;
        })
            .catch((err) => {
            if (err?.status === 404) {
                toast.error({
                    title: 'Página não encontrada',
                    message: 'O método de validação de certificados não foi encontrado. Por favor, entre em contato com o suporte.'
                });
                return null;
            }
        });
    },
    printUserCertificate(certificadoId, cursoFormacaoId, usuarioId) {
        return certificateService
            .get(`Imprimir/${certificadoId}/${cursoFormacaoId}/${usuarioId}`)
            .then(({ success, data }) => ({
            success,
            data: {
                ...data,
                course: data.curso?.itensDoCurso?.map(item => ({
                    name: item.midia?.nome || item.avaliacao?.titulo,
                    duration: item.midia?.duracao || null,
                    type: item.tipo
                })) || [],
                trail: data.formacao?.etapas?.map(step => ({
                    name: step.nome,
                    duration: step?.duracao,
                    items: step?.itens?.map(item => ({
                        name: item.nome,
                        duration: item?.duracao,
                        type: item.tipo,
                        items: item?.itens?.map(subItem => ({
                            name: subItem.nome,
                            duration: subItem.duracao
                        })) || []
                    })) || []
                })) || []
            }
        }));
    },
};
class CertificadosService extends BaseService {
    constructor() {
        super({ resource: 'certificado' });
    }
    async getCertData(certificadoId, cursoFormacaoId, usuarioId) {
        return this.plantarApi.get(`${this.resource}/Imprimir/${certificadoId}/${cursoFormacaoId}/${usuarioId}`);
    }
    async imprimir(certificadoId, cursoFormacaoId) {
        return certificateService.getCertData(certificadoId, cursoFormacaoId);
    }
    async imprimirCertificadoUsuario(certificadoId, cursoFormacaoId, usuarioId) {
        return certificateService.printUserCertificate(certificadoId, cursoFormacaoId, usuarioId);
    }
    async upload(obj) {
        const postData = new FormData();
        const keys = Object.keys(obj.data);
        for (let i = 0; i < keys.length; i++) {
            postData.append(keys[i], obj.data[keys[i]]);
        }
        try {
            const { data, success } = await this.plantarApi.post(`${this.resource}/FileUpload/${obj.certificadoId}/${obj.alias}`, postData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return { data, success, notifications: [] };
        }
        catch (error) {
            const { notifications, success } = error.response?.data || {};
            return {
                data: {},
                success,
                notifications
            };
        }
    }
}
const certificadosService = new CertificadosService();
export default certificadosService;
