import { toast } from '@/common/util';
import { notificationHubApi } from '@/config/appAPIs';
import plantarApi from './base.api';
const api = {
    resource: '',
    setResource(resource) {
        this.resource = resource;
        return this;
    },
    getPath(resourceId) {
        return resourceId ? [this.resource, resourceId].join('/') : this.resource;
    },
    async get(resourceId, params) {
        return await notificationHubApi.get(this.getPath(resourceId), {
            params,
            paramsSerializer: {
                indexes: null
            }
        });
    },
    async post(resourceId, data) {
        return await notificationHubApi.post(this.getPath(resourceId), data);
    },
    async put(resourceId, data) {
        return await notificationHubApi.put(this.getPath(resourceId), data);
    },
    async delete(resourceId) {
        return await notificationHubApi.delete(this.getPath(resourceId));
    },
    // Registrar o token do usuário para receber notificações push no dispositivo atual
    async deviceTokenPlantar({ token }) {
        try {
            // const result = await this.post('DeviceToken', {
            //   userId,
            //   userName,
            //   type: 'PUSH',
            //   device: device || 'web app aliare integra',
            //   product: product || 'playground',
            //   token
            // })
            const result = await plantarApi.post(`Notificacao/VincularDispositivo/${token}`);
            return result;
        }
        catch (error) {
            console.log('Problema ao registrar o token do usuário', error);
        }
    },
    async deviceToken({ userId, userName, token, device, product }) {
        try {
            const { data, success } = await this.post('DeviceToken', {
                userId,
                userName,
                type: 'PUSH',
                device: device || 'web app aliare integra',
                product: product || 'plantar-educacao',
                token
            });
            return { data, success };
        }
        catch (error) {
            toast.error({
                title: 'Falha ao cadastrar dispositivo',
                message: 'teste'
            });
            console.log('Toasted Problema ao registrar o token do usuário');
        }
    },
    // Adicionar uma nova notificação push
    async postPush({ userId, userName, title, content, product }) {
        try {
            const result = await this.post('Push', {
                userId,
                userName,
                title,
                content,
                product: product || 'plantar-educacao'
            });
            return result;
        }
        catch (error) {
            console.log('Problema ao gerar notificação', error);
            throw new Error('Problema ao gerar notificação');
        }
    },
    //Recupera a lista de notificações push
    async getPushList({ page, size, userId }) {
        try {
            const { success, data } = (await this.get('Push/List', {
                UsuarioId: userId,
                page: page || 1,
                size: size || 10
            }))?.data || { success: false, data: [] };
            return { success, data };
        }
        catch (error) {
            console.log('Problema ao recuperar a lista de notificações', error);
            throw new Error('Problema ao recuperar a lista de notificações');
        }
    },
    async getPushListPlantar({ page, size, userId }) {
        try {
            const { data, success } = await plantarApi.get('Notificacao/PushList', {
                UsuarioId: userId,
                page: page || 1,
                size: size || 10
            });
            return { success, data };
        }
        catch (error) {
            console.log('Problema ao recuperar a lista de notificações', error);
            throw new Error('Problema ao recuperar a lista de notificações');
        }
    },
    /**
     * Recupera uma notificação push por id
     * @param param0
     */
    async getPush({ id }) {
        try {
            const { data } = await this.get(`Push/${id}`);
            return { ...data };
        }
        catch (error) {
            console.log('Problema ao recuperar a notificação', error);
            throw new Error('Problema ao recuperar a notificação');
        }
    },
    /**
     * Deleta uma notificação push por id
     * @param param0
     */
    async deletePush({ id }) {
        try {
            const { data } = await this.delete(`Push/${id}`);
            return { ...data };
        }
        catch (error) {
            console.log('Problema ao deletar a notificação', error);
            throw new Error('Problema ao deletar a notificação');
        }
    },
    // Marcar como lida
    async markAsRead({ id }) {
        try {
            const { data } = await this.post(`Push/Lida/${id}`);
            return { ...data };
        }
        catch (error) {
            // error.response?.status
            toast.error({
                title: 'Erro!',
                message: 'Não foi possível marcar a notificação como lida'
            });
            throw new Error('Problema ao marcar notificação como lida');
        }
    }
};
export default api;
