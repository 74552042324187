import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue';
import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["disabled"];
import { computed } from 'vue';
import UiIcon from '@/components/ui/icon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'switch',
    props: /*@__PURE__*/ _mergeModels({
        disabled: { type: Boolean },
        size: {},
        inverted: { type: Boolean },
        useIcons: { type: Boolean, default: false },
        onIcon: { default: 'player-play-filled' },
        offIcon: { default: 'player-pause-filled' },
        beforeChange: {}
    }, {
        "modelValue": { type: Boolean },
        "modelModifiers": {},
    }),
    emits: ["update:modelValue"],
    setup(__props) {
        const props = __props;
        const model = _useModel(__props, "modelValue");
        const getSize = computed(() => {
            const sz = (props.size || '').toLowerCase();
            if (['small', 'medium', 'large'].includes(sz))
                return sz;
            return 'medium';
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("label", {
                class: _normalizeClass([
                    'inline-flex items-center bg-gray-300 rounded-full p-0.5 duration-300 ease-in-out shrink-0', {
                        'w-8 h-4': getSize.value === 'small',
                        'w-10 h-5': getSize.value === 'medium',
                        'w-16 h-8': getSize.value === 'large',
                        'bg-green-400': (_ctx.inverted ? !model.value : model.value)
                    }, props.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
                ])
            }, [
                _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((model).value = $event)),
                    disabled: props.disabled,
                    class: "hidden"
                }, null, 8, _hoisted_1), [
                    [_vModelCheckbox, model.value]
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(['bg-white rounded-full shadow-md transform duration-300 ease-in-out flex items-center justify-center', {
                            'w-3 h-3': getSize.value === 'small',
                            'w-4 h-4': getSize.value === 'medium',
                            'w-7 h-7': getSize.value === 'large',
                            'translate-x-4': (_ctx.inverted ? !model.value : model.value) && getSize.value === 'small',
                            'translate-x-5': (_ctx.inverted ? !model.value : model.value) && getSize.value === 'medium',
                            'translate-x-8': (_ctx.inverted ? !model.value : model.value) && getSize.value === 'large'
                        }])
                }, [
                    (_ctx.useIcons)
                        ? (_openBlock(), _createBlock(UiIcon, {
                            key: 0,
                            icon: model.value ? _ctx.onIcon : _ctx.offIcon,
                            size: {
                                small: 8,
                                medium: 12,
                                large: 20
                            }[getSize.value],
                            class: _normalizeClass(_ctx.inverted ? !model.value : model.value ? 'text-green-400' : 'text-neutral-400')
                        }, null, 8, ["icon", "size", "class"]))
                        : _createCommentVNode("", true)
                ], 2)
            ], 2));
        };
    }
});
