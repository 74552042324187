import { toast, aliareProducts } from '@/common/util';
import baseApi from './base.api';
import { plantarApiV2 } from '@/config/appAPIs';
export const contratoService = {
    ...baseApi,
    resource: 'Contrato',
    getAll(opt, options) {
        opt = opt || {};
        const paramsDictionary = {};
        const orderDictionary = {};
        const extraData = {};
        Object.keys(opt).forEach((key) => {
            if (typeof paramsDictionary[key] !== 'undefined' && typeof opt[key] !== 'undefined' && opt[key] !== null)
                extraData[paramsDictionary[key]] = opt[key];
        });
        // Ordenação
        if (opt.orderBy?.length) {
            extraData['Ordenacao'] = opt.orderBy.map((item) => `${orderDictionary[item.prop]}${item.order === 'DESC' ? 'Desc' : 'Asc'}`);
        }
        return contratoService
            .get('', extraData, options)
            .then(({ success, data }) => {
            return {
                success,
                // Normaliza a extrutura recebida, para o formato local
                data: data.map((item) => ({
                    id: item.id,
                    personId: item.pessoaId,
                    agbAccountCode: item.codigoContaAGB,
                    expirationDate: item.dataDeValidade ? new Date(item.dataDeValidade) : null,
                    contractDate: item.dataDeContrato ? new Date(item.dataDeContrato) : null,
                    observation: item.observacao,
                    usersQuantity: item.quantidadeDeUsuariosContratados,
                    diskSpace: item.espacoEmDiscoContratado,
                    coursesRecordsLimit: item.limiteDeRegistrosDeCursos,
                    trailsRecordsLimit: item.limiteDeRegistroDeFormacoes,
                    file: item.arquivo,
                    responsibleName: item.nomeDoResponsavel,
                    responsibleEmail: item.emailDoResponsavel,
                    responsibleCellphone: item.celularDoResponsavel,
                    defaultAccessUrl: item.urlDeAcessoPadrao,
                    customAccessUrl: item.urlDeAcessoPersonalizado,
                    communicationEmail: item.emailDeComunicacaoUsadoPelaEmpresa,
                    managerPersonType: item.tipoPessoaGestor,
                    managerCpf: item.cpfGestor,
                    managerEmail: item.emailGestor,
                    managerName: item.nomeGestor,
                    managerPersonId: item.personIdGestor,
                    managerUserId: item.userIdGestor,
                    contractTenantId: item.tenantIdDoContrato,
                    owner: item.owner,
                    bunnyVideoCollectionId: item.bunnyVideoCollectionId,
                    active: item.active,
                    companyName: item.nomeEmpresa,
                    aliareIdentificationDocumentPeople: item.aliareIdentificationDocumentPeople,
                    aliareTypePeople: item.aliareTypePeople,
                    activeUsersQuantity: item.qtdeUsuarioAtivos,
                    sectionsIds: item.secoesIds,
                    aliareSubscriptionId: item.aliareSubscriptionId,
                    createdAt: new Date(item.createdAt),
                    clientClassification: item.classificacaoCliente,
                    projectStatus: item.statusProjeto,
                    companyId: item.empresaId,
                    products: item.produtos
                        ? item.produtos.split(',')
                            .map((product) => aliareProducts.value[product.trim()] || '-')
                            .join(', ')
                        : '',
                    communicationsContracts: item.contratosComunicacoes?.map((cm) => ({
                        id: cm.id,
                        communicationId: cm.communicationId,
                        contractId: cm.contractId
                    })) || []
                }))
            };
        });
    },
    async isDistribuidor() {
        try {
            return await this.get(`IsDistribuidor`);
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao verificar informações do usuário!' });
        }
    },
    async getUsageInfo() {
        try {
            return await this.get(`UsageInfo`);
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao buscar informações do contrato!' });
        }
    },
    /*
     * Endpoints V2
     */
    async create(newContract) {
        try {
            return await plantarApiV2.post(this.resource, { ...newContract });
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao criar contrato!' });
        }
    },
    getTenants(options) {
        const { ownerId } = options || {};
        if (!ownerId) {
            return Promise.reject('ownerId is required');
        }
        delete options.ownerId;
        return plantarApiV2.get(`${this.resource}/tenants/${ownerId}`, {
            data: options || {}
        });
    }
};
export default contratoService;
