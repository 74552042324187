import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = {
    key: 1,
    class: "flex items-center justify-center gap-1"
};
import { computed, useAttrs } from 'vue';
import { RouterLink } from 'vue-router';
import UiIcon from './icon.vue';
export default /*@__PURE__*/ _defineComponent({
    ...{
        inheritAttrs: false,
        name: 'UiButton'
    },
    __name: 'button',
    props: {
        type: { default: 'primary' },
        round: { type: Boolean },
        rounded: { type: Boolean },
        disabled: { type: Boolean },
        loading: { type: Boolean },
        loadingText: {},
        size: {},
        icon: {},
        iconSize: {},
        outline: { type: Boolean },
        text: {},
        to: {},
        title: {}
    },
    setup(__props) {
        const attrs = useAttrs();
        const buttonClass = computed(() => {
            const classes = [];
            if (['primary', 'info', 'success', 'warning', 'danger', 'ghost', 'link'].includes(__props.type))
                classes.push(__props.type);
            if (__props.outline)
                classes.push('uib-outline');
            if (__props.round)
                classes.push('aspect-square !rounded-full');
            else if (__props.rounded)
                classes.push('!rounded-full');
            return classes;
        });
        const componentProps = computed(() => {
            if (__props.to && !__props.disabled) {
                return { to: __props.to, ...attrs };
            }
            return { type: 'button', disabled: __props.disabled || __props.loading, ...attrs };
        });
        return (_ctx, _cache) => {
            const _directive_tooltip = _resolveDirective("tooltip");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.to && !_ctx.disabled ? _unref(RouterLink) : 'button'), _mergeProps(componentProps.value, {
                title: _ctx.title || '',
                class: ['ui-button', buttonClass.value]
            }), {
                default: _withCtx(() => [
                    (_ctx.loading)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                            _createVNode(UiIcon, {
                                class: "animate-spin",
                                icon: "loader",
                                size: _ctx.iconSize
                            }, null, 8, ["size"]),
                            _createTextVNode(" " + _toDisplayString(_ctx.loadingText || _ctx.$t('feedback.await')), 1)
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            (_ctx.icon)
                                ? (_openBlock(), _createBlock(UiIcon, {
                                    key: 0,
                                    icon: _ctx.icon,
                                    size: _ctx.iconSize
                                }, null, 8, ["icon", "size"]))
                                : _createCommentVNode("", true),
                            _renderSlot(_ctx.$slots, "default", {}, () => [
                                _createTextVNode(_toDisplayString(_ctx.text), 1)
                            ])
                        ]))
                ]),
                _: 3
            }, 16, ["title", "class"])), [
                [_directive_tooltip]
            ]);
        };
    }
});
