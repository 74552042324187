import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Transition as _Transition, Teleport as _Teleport, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "fixed z-[9999] px-4 top-0 left-0 h-screen w-screen overflow-hidden bg-black/50 dark:bg-white/30 transition-opacity duration-500 flex flex-wrap items-center justify-center"
};
const _hoisted_2 = { class: "box-border z-10 max-w-[412px] flex flex-col w-full rounded-md bg-[#FDFDFD] py-4 px-4 text-[#666666] dark:text-[#FDFDFD] dark:bg-[#1D1D1D]" };
const _hoisted_3 = { class: "font-semibold text-[#212121] dark:text-[#FDFDFD]" };
const _hoisted_4 = ["innerHTML"];
const _hoisted_5 = { class: "flex flex-wrap gap-2 justify-end" };
import { nextTick, onMounted } from 'vue';
import { useNotificationsStore } from '@/stores';
import UiButton from '@/components/ui/button.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'dialog',
    props: /*@__PURE__*/ _mergeModels({
        id: {},
        title: {},
        message: {},
        complete: { type: Function },
        cancel: { type: Function },
        typeConfirmButton: {},
        textConfirmButton: {},
        immediate: { type: Boolean }
    }, {
        "modelValue": { type: Boolean },
        "modelModifiers": {},
    }),
    emits: ["update:modelValue"],
    setup(__props, { expose: __expose }) {
        const props = __props;
        const model = _useModel(__props, "modelValue");
        const notificationsStore = useNotificationsStore();
        const localPromise = {
            resolve: null,
            reject: null
        };
        const dismiss = () => {
            if (props.id) {
                notificationsStore.dismissNewDialog(props.id);
            }
            model.value = false;
        };
        const onConfirm = async () => {
            if (typeof props?.complete === 'undefined' || (typeof await props?.complete === 'function' && (await props?.complete?.() === false))) {
                if (localPromise.resolve)
                    localPromise.resolve();
            }
            dismiss();
        };
        const onCancel = async () => {
            if (typeof props?.cancel && 'function' && (await props?.cancel?.() === false)) {
                localPromise.reject();
            }
            dismiss();
        };
        const open = () => {
            model.value = true;
            return new Promise((resolve, reject) => {
                localPromise.resolve = resolve;
                localPromise.reject = reject;
            });
        };
        onMounted(() => {
            if (typeof model.value === 'undefined' || props.immediate) {
                model.value = true;
                nextTick(() => {
                    document?.getElementById('buttonConfirm').focus();
                });
            }
        });
        __expose({
            open
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
                (typeof model.value === 'undefined' || model.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createVNode(_Transition, {
                            mode: "out-in",
                            "enter-active-class": "transition duration-500 transform",
                            "enter-from-class": "opacity-0 translate-y-full",
                            "leave-active-class": "duration-500 transform",
                            "leave-to-class": "opacity-0 translate-y-full",
                            appear: ""
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_2, [
                                    _renderSlot(_ctx.$slots, "default", {}, () => [
                                        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title || _ctx.$t('feedback.attention')), 1),
                                        (_ctx.message)
                                            ? (_openBlock(), _createElementBlock("div", {
                                                key: 0,
                                                class: "text-sm leading-[18px] h-full mb-4",
                                                innerHTML: _ctx.message
                                            }, null, 8, _hoisted_4))
                                            : _createCommentVNode("", true)
                                    ]),
                                    _createElementVNode("div", _hoisted_5, [
                                        _createVNode(UiButton, {
                                            type: "danger",
                                            outline: "",
                                            class: "p-3 uppercase",
                                            onClick: onCancel
                                        }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t('button.cancel')), 1)
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(UiButton, {
                                            id: "buttonConfirm",
                                            type: props.typeConfirmButton ?? 'primary',
                                            class: "p-3 uppercase",
                                            onClick: onConfirm
                                        }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.textConfirmButton ?? _ctx.$t('button.confirm')), 1)
                                            ]),
                                            _: 1
                                        }, 8, ["type"])
                                    ])
                                ])
                            ]),
                            _: 3
                        })
                    ]))
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
