import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, unref as _unref, createBlock as _createBlock, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "transition-gradient flex items-center justify-center"
};
const _hoisted_2 = { class: "hidden sm:block relative w-0 lg:w-1/2 overflow-hidden grow-0" };
const _hoisted_3 = { class: "shrink-0 w-full lg:w-1/2 relative box-border flex h-full flex-col items-center justify-between overflow-y-auto bg-[#FDFDFD] text-[#212121] dark:bg-base-100/95 dark:text-[#FDFDFD] lg:bg-[#FDFDFD]/100 dark:lg:bg-base-100" };
const _hoisted_4 = { class: "absolute flex flex-row-reverse gap-2 right-4 top-4" };
const _hoisted_5 = { class: "box-border w-[396px] max-w-full grow px-6 mt-8 lg:mt-10" };
const _hoisted_6 = {
    key: 0,
    class: "flex flex-wrap items-center gap-1 text-sm justify-center"
};
import { computed, defineAsyncComponent, ref, watchEffect } from 'vue';
import useSystemStore from '@/stores/systemStore';
import { arrRand } from '@/common/util';
import UiButton from '@/components/ui/button.vue';
import UiIcon from '@/components/ui/icon.vue';
import UiLangSelector from '@/components/ui/langSelector.vue';
import UiLoader from '@/components/ui/loader.vue';
// import LogoAliare from './components/logoAliare.vue'
import CookiesDialog from './components/cookies.vue';
import LoginCarousel from './components/carousel.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    setup(__props) {
        _useCssVars(_ctx => ({
            "42f0200f": (bgImage.value)
        }));
        const LoginBackground = defineAsyncComponent(() => import('./components/loginBackground.vue'));
        const systemStore = useSystemStore();
        const preload = ref(true);
        const flip = ref(false);
        const isLeft = computed(() => flip.value || systemStore.clientSettings.settings.login.align === 'left');
        const contactSupport = () => {
            document.getElementsByClassName('md-chat-widget-btn-wrapper')[0]?.click();
        };
        const bgImage = computed(() => {
            if (systemStore.getConfig.login?.backgrounds?.length > 0)
                return arrRand(systemStore.getConfig.login.backgrounds)[0];
            return false;
        });
        const carousels = computed(() => systemStore.getConfig.login.carousels);
        watchEffect(() => {
            if (systemStore.clientSettings.status === 'complete') {
                setTimeout(() => {
                    preload.value = false;
                }, 400);
            }
        });
        return (_ctx, _cache) => {
            const _component_router_view = _resolveComponent("router-view");
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["relative box-border w-screen h-screen flex overflow-hidden", { 'flex-row-reverse': isLeft.value }])
            }, [
                _createVNode(_Transition, {
                    mode: "out-in",
                    "enter-active-class": "transition",
                    "enter-from-class": "opacity-0",
                    "leave-active-class": "transition",
                    "leave-to-class": "opacity-0"
                }, {
                    default: _withCtx(() => [
                        (preload.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                _createVNode(UiLoader, { size: "80px" })
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }),
                _createElementVNode("div", _hoisted_2, [
                    (_unref(systemStore).clientSettings.loadedOnce)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (!carousels.value?.length)
                                ? (_openBlock(), _createBlock(_unref(LoginBackground), {
                                    key: 0,
                                    bgImage: bgImage.value || '',
                                    isLeft: isLeft.value
                                }, null, 8, ["bgImage", "isLeft"]))
                                : (_openBlock(), _createBlock(LoginCarousel, {
                                    key: 1,
                                    carousels: carousels.value
                                }, null, 8, ["carousels"]))
                        ], 64))
                        : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                        _createVNode(UiButton, {
                            type: "ghost",
                            class: "p-2 !rounded-full",
                            round: "",
                            onClick: _cache[0] || (_cache[0] = ($event) => (_unref(systemStore).toggleTheme()))
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_Transition, {
                                    mode: "out-in",
                                    "enter-active-class": "transition ease-out duration-150 transform",
                                    "enter-from-class": "opacity-50 rotate-90",
                                    "leave-active-class": "ease-in duration-200",
                                    "leave-to-class": "opacity-50 -rotate-90"
                                }, {
                                    default: _withCtx(() => [
                                        (_unref(systemStore).isDark)
                                            ? (_openBlock(), _createBlock(UiIcon, {
                                                key: 0,
                                                icon: "moon",
                                                size: 28
                                            }))
                                            : (_openBlock(), _createBlock(UiIcon, {
                                                key: 1,
                                                icon: "sun-high",
                                                size: 28
                                            }))
                                    ]),
                                    _: 1
                                })
                            ]),
                            _: 1
                        }),
                        _createElementVNode("div", null, [
                            _createVNode(UiButton, {
                                type: "ghost",
                                class: "w-11 h-11",
                                round: ""
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(UiLangSelector, { size: "lg" })
                                ]),
                                _: 1
                            })
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_router_view, null, {
                            default: _withCtx(({ Component }) => [
                                _createVNode(_Transition, {
                                    appear: "",
                                    mode: "out-in",
                                    "enter-active-class": "transition ease-out duration-150 transform",
                                    "enter-from-class": "opacity-0 translate-x-8",
                                    "leave-active-class": "ease-in duration-200",
                                    "leave-to-class": "opacity-0 -translate-x-8"
                                }, {
                                    default: _withCtx(() => [
                                        (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                                    ]),
                                    _: 2
                                }, 1024)
                            ]),
                            _: 1
                        }),
                        (!_unref(systemStore).getConfig.hideSupport)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createTextVNode(_toDisplayString(_ctx.$t('login.helpNeeded')) + " ", 1),
                                _createElementVNode("a", {
                                    href: "#",
                                    class: "font-semibold",
                                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event) => (contactSupport()), ["prevent"]))
                                }, _toDisplayString(_ctx.$t('login.talkToUs')), 1)
                            ]))
                            : _createCommentVNode("", true)
                    ])
                ]),
                _createVNode(CookiesDialog)
            ], 2));
        };
    }
});
