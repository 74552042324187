import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, Transition as _Transition } from "vue";
import _imports_0 from '@/assets/perfilAjuda.svg';
const _hoisted_1 = { class: "relative" };
const _hoisted_2 = { class: "mb-6 flex flex-col-reverse md:flex-row items-center gap-x-6" };
const _hoisted_3 = { class: "rounded-3xl bg-white dark:bg-neutral-800/50 p-4" };
const _hoisted_4 = { class: "flex flex-col items-center gap-6 md:flex-row" };
const _hoisted_5 = ["innerHTML"];
const _hoisted_6 = { class: "relative max-w-[164px]" };
const _hoisted_7 = { class: "relative aspect-square w-[164px] max-w-full rounded-full ring-2 ring-offset-4 ring-[--c-primary-500] bg-[--c-primary-300] overflow-hidden" };
const _hoisted_8 = { class: "flex h-full items-center justify-center" };
const _hoisted_9 = ["src"];
const _hoisted_10 = {
    key: 1,
    class: "text-center text-6xl text-white"
};
const _hoisted_11 = { class: "flex p-2 items-center justify-center rounded-full bg-neutral-300 dark:bg-neutral-800" };
const _hoisted_12 = { class: "text-xs text-neutral-100" };
const _hoisted_13 = { class: "absolute -bottom-8 w-full" };
const _hoisted_14 = { class: "mb-6 flex flex-wrap gap-x-6 cursor-pointer m-0 p-0 z-auto" };
const _hoisted_15 = ["onClick", "data-selected"];
const _hoisted_16 = {
    key: 0,
    class: "grid grid-cols-8 gap-x-4 gap-y-4"
};
const _hoisted_17 = {
    key: 0,
    id: "birthdateInput",
    class: "absolute bottom-1 right-8 text-3xl"
};
const _hoisted_18 = {
    key: 1,
    class: "grid grid-cols-9 gap-8"
};
const _hoisted_19 = {
    key: 2,
    class: "grid grid-cols-8 grid-rows-2 gap-8"
};
import { reactive, ref, onMounted, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { useReward } from 'vue-rewards';
import { arrToLabelValue, capitalizedName, getInitials, dialog, toast, validateFileSize } from '@/common/util';
import { useAuthStore, useCompetenciasStore, useUsuariosStore, } from '@/stores';
import UiIcon from '@/components/ui/icon.vue';
import UiInput from '@/components/ui/input.vue';
import UiSelect from '@/components/ui/select.vue';
import UiFormControl from '@/components/ui/formControl.vue';
import UiDatePicker from '@/components/ui/datepicker.vue';
import UiInputPhone from '@/components/ui/inputNumberPhone.vue';
import BirthdayTheme from './birthdayTheme.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'content',
    props: ['editId'],
    emits: ['onComplete'],
    setup(__props, { expose: __expose, emit: __emit }) {
        const competenciasStore = useCompetenciasStore();
        const usuariosStore = useUsuariosStore();
        const authStore = useAuthStore();
        const loaded = ref(false);
        const tabsSelected = ref(1);
        const selected = ref(null);
        const fileinput = ref();
        const formRef = ref();
        const birthday = ref(false);
        const originalCompetences = ref([]);
        const { t } = useI18n();
        const emit = __emit;
        const tabs = [
            {
                id: 1,
                title: t('tabs.personalData'),
                info: t('tooltips.profile.personalData')
            },
            {
                id: 2,
                title: t('tabs.corporateData'),
                info: t('tooltips.profile.corporateData')
            },
            {
                id: 3,
                title: t('tabs.academicData'),
                info: t('tooltips.profile.academicData')
            }
        ];
        const defaultValues = {
            nomeCompleto: null,
            email: null,
            senha: null,
            cpf: null,
            telefone: null,
            tipoPessoa: null,
            apelido: null,
            genero: null,
            idiomaPreferido: null,
            dataDeAdmissao: null,
            superiorDireto: null,
            interesseDoSuperior: null,
            interessesDoUsuario: [],
            notificarViaPush: false,
            notificarViaSMS: false,
            foto: null,
            storageUrlDaFoto: null,
            fotoLink: null,
            peopleId: null,
            cargoId: null,
            departamentoId: null,
            unidadeDeNegocioId: null,
            ativo: true,
            escolaridade: null,
            dataDeNascimento: null,
            usuariosCompetencias: [],
            cargoNome: null,
            unidadeDeNegocioNome: null,
            departamentoNome: null,
            superiorDiretoNome: null
        };
        const form = reactive({ ...defaultValues });
        const rules = reactive({
            nomeCompleto: [
                {
                    name: 'required',
                    test: (value) => !!value.trim(),
                    message: 'O nome é obrigatório'
                },
                {
                    name: 'maxLength',
                    test: (value) => `${value}`.length <= 60,
                    message: 'O nome não pode ultrapassar 60 caracteres'
                }
            ],
        });
        const generosList = arrToLabelValue(['Masculino', 'Feminino', 'Não-Binário']);
        const idiomasList = arrToLabelValue(['Português', 'Inglês', 'Espanhol']);
        const escolaridadeList = arrToLabelValue([
            'Ensino Fundamental Incompleto',
            'Ensino Fundamental Completo',
            'Ensino Médio Incompleto',
            'Ensino Médio Completo',
            'Formação Superior Incompleta',
            'Formação Superior Completa',
            'Pós-graduação Incompleta',
            'Pós-graduação Completa',
            'Mestrado Incompleto',
            'Mestrado Completo',
            'Doutorado Incompleto',
            'Doutorado Completo'
        ]);
        const activeTab = (item) => {
            tabsSelected.value = item.id;
        };
        const resetPassword = async () => {
            dialog.open({
                title: 'Mudança de Senha',
                alt: true,
                message: `
      Será enviado um e-mail para : <strong>${form.email}</strong> com orientações para você redefinir a sua senha. 
      <p>Após confirmar, acesse sua caixa de entrada, localize o e-mail e siga as instruções para alteração.</p>
      <p>Dica! Se o e-mail não estiver correto, contate agora mesmo o seu gestor ou administrador da plataforma.</p>`,
                complete: async () => {
                    try {
                        await usuariosStore.sendEmailChangePassword(form.email);
                        toast.success({
                            title: t('feedback.success'),
                            message: t('feedback.successRequestPassword')
                        });
                    }
                    catch (error) {
                        toast.error({ message: error });
                    }
                }
            });
        };
        const onChange = () => {
            const file = fileinput.value?.files[0];
            if (file && !validateFileSize(file.size, 1, 'MB'))
                return false;
            if (file && file.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => (form.foto = reader.result);
            }
            else {
                form.foto = null;
            }
        };
        /**
         * Ação de submeter formulário
         * @param opt Se informado "cancel" o fluxo de envio é interrompido
         * Caso contrário e caso o form seja válido um post/put é submetido
         * a API e em caso de sucesso o componente PAI recebe um emit "onComplete"
         * contendo a resposta da api
         */
        const submit = async (opt) => {
            if (opt === 'cancel') {
                emit('onComplete');
                return true;
            }
            if (!formRef.value.validate()) {
                toast.error({
                    message: t('feedback.formError')
                });
                return false;
            }
            if (form.dataDeNascimento && new Date(form.dataDeNascimento) > new Date()) {
                toast.error({
                    message: t('feedback.futureBirthday')
                });
                return false;
            }
            let usuariosCompetencias = [];
            if (form.usuariosCompetencias.length > 0) {
                usuariosCompetencias = form.usuariosCompetencias.map(competenciaId => {
                    const existente = originalCompetences.value.find(item => item.competenciaId === competenciaId);
                    return existente ? existente : { competenciaId };
                });
            }
            const formData = {
                ...form,
                nomeCompleto: capitalizedName(form.nomeCompleto),
                dataDeNascimento: form.dataDeNascimento || null,
                dataTermoDeUsoAceito: (form.dataTermoDeUsoAceito?.includes('/') ? form.dataTermoDeUsoAceito.split('/').reverse().join('-') : form.dataTermoDeUsoAceito) || null,
                telefone: form.telefone ? form.telefone.replace(/\D/g, '') : null,
                usuariosCompetencias: usuariosCompetencias
            };
            if (formData.foto?.includes('http'))
                delete formData.foto;
            const result = await usuariosStore.update(formData, formData.id);
            await authStore.getUserInfo(formData.id);
            if (result?.id)
                emit('onComplete', result);
            return true;
        };
        const loadDataList = async () => {
            if (!competenciasStore.list.length)
                competenciasStore.getAll();
        };
        onMounted(async () => {
            birthday.value = false;
            await loadDataList();
            originalCompetences.value = authStore.userLogged.usuariosCompetencias;
            const assignData = {
                ...authStore.userLogged,
                usuariosCompetencias: authStore.userLogged.usuariosCompetencias.map((el) => el.competenciaId)
            };
            Object.assign(form, assignData);
            loaded.value = true;
            nextTick(() => {
                if (tabs[0]?.id == 1)
                    selected.value[0].classList.add('indicator');
                if (!form?.dataDeNascimento)
                    return;
                const birthdate = new Date(form?.dataDeNascimento);
                const today = new Date();
                if (birthdate.getDate() === today.getDate() && birthdate.getMonth() === today.getMonth()) {
                    birthday.value = true;
                    setTimeout(() => {
                        const { reward: balloonsReward } = useReward('birthdateInput', 'balloons');
                        const cake1 = useReward('birthdateInput', 'confetti', {
                            zIndex: 2000,
                            lifetime: 300,
                            elementCount: 50,
                            startVelocity: 30,
                            spread: 60,
                            angle: 140,
                            elementSize: 6
                        });
                        const cake2 = useReward('birthdateInput', 'confetti', {
                            zIndex: 2000,
                            lifetime: 300,
                            elementCount: 50,
                            startVelocity: 30,
                            spread: 20,
                            angle: 60,
                            elementSize: 6
                        });
                        const emoji = useReward('birthdateInput', 'emoji', {
                            zIndex: 2000,
                            lifetime: 100,
                            elementCount: 4,
                            emoji: ['🥳']
                        });
                        balloonsReward();
                        cake1.reward();
                        cake2.reward();
                        emoji.reward();
                    }, 2000);
                }
            });
        });
        __expose({
            submit,
            resetPassword
        });
        return (_ctx, _cache) => {
            const _component_pe_tooltip = _resolveComponent("pe-tooltip");
            const _component_pe_form = _resolveComponent("pe-form");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                            _cache[17] || (_cache[17] = _createElementVNode("div", { class: "w-full max-w-[200px] lg:max-w-[140px]" }, [
                                _createElementVNode("img", {
                                    src: _imports_0,
                                    alt: "",
                                    class: "scale-125"
                                })
                            ], -1)),
                            _createElementVNode("div", {
                                class: "w-full px-4 text-justify",
                                innerHTML: _ctx.$t('helpers.profile')
                            }, null, 8, _hoisted_5)
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("div", _hoisted_8, [
                                (form.foto)
                                    ? (_openBlock(), _createElementBlock("img", {
                                        key: 0,
                                        src: form.foto,
                                        class: "w-full h-full object-cover"
                                    }, null, 8, _hoisted_9))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_unref(getInitials)(form.nomeCompleto) || '-'), 1))
                            ]),
                            _createElementVNode("div", {
                                class: "flex flex-col gap-2 w-full h-full items-center justify-center absolute cursor-pointer transition duration-200 rounded-full top-0 opacity-0 hover:opacity-100 bg-black/50",
                                onClick: _cache[0] || (_cache[0] = ($event) => (fileinput.value.click()))
                            }, [
                                _createElementVNode("div", _hoisted_11, [
                                    _createVNode(UiIcon, { icon: "upload" })
                                ]),
                                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('input.photoUpload')), 1)
                            ]),
                            _createElementVNode("div", _hoisted_13, [
                                _createElementVNode("input", {
                                    ref_key: "fileinput",
                                    ref: fileinput,
                                    type: "file",
                                    accept: "image/png, image/jpeg, image/jpg",
                                    class: "hidden",
                                    onChange: _cache[1] || (_cache[1] = ($event) => (onChange()))
                                }, null, 544)
                            ])
                        ]),
                        (birthday.value)
                            ? (_openBlock(), _createBlock(BirthdayTheme, { key: 0 }))
                            : _createCommentVNode("", true)
                    ])
                ]),
                _createElementVNode("div", null, [
                    _createElementVNode("ul", _hoisted_14, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(tabs, (item) => {
                            return _createElementVNode("li", {
                                key: item.id,
                                onClick: ($event) => (activeTab(item)),
                                class: _normalizeClass(["navlink font-bold py-1", { indicator: tabsSelected.value == item.id }]),
                                ref_for: true,
                                ref_key: "selected",
                                ref: selected,
                                tabindex: "0",
                                "data-selected": `${item.id}`
                            }, [
                                _createTextVNode(_toDisplayString(item.title) + " ", 1),
                                _createVNode(_component_pe_tooltip, {
                                    text: item.info,
                                    icon: "",
                                    position: "top"
                                }, null, 8, ["text"])
                            ], 10, _hoisted_15);
                        }), 64))
                    ]),
                    _createVNode(_component_pe_form, {
                        ref_key: "formRef",
                        ref: formRef,
                        "form-data": form,
                        rules: rules,
                        "auto-validate": "",
                        class: "mb-6"
                    }, {
                        default: _withCtx(() => [
                            (tabsSelected.value == tabs[0].id)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                    _createVNode(UiFormControl, {
                                        label: _ctx.$t('labels.fullname'),
                                        prop: "nomeCompleto",
                                        required: "",
                                        class: "col-span-full md:col-span-4"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(UiInput, {
                                                modelValue: form.nomeCompleto,
                                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((form.nomeCompleto) = $event)),
                                                modelModifiers: { trim: true },
                                                id: "title",
                                                maxlength: "60",
                                                placeholder: _ctx.$t('placeholder.fullname'),
                                                class: "w-full"
                                            }, null, 8, ["modelValue", "placeholder"])
                                        ]),
                                        _: 1
                                    }, 8, ["label"]),
                                    _createVNode(UiFormControl, {
                                        class: "col-span-full md:col-span-4",
                                        prop: "apelido",
                                        label: _ctx.$t('labels.calledBy'),
                                        tooltip: _ctx.$t('tooltips.profile.calledBy')
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(UiInput, {
                                                modelValue: form.apelido,
                                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((form.apelido) = $event)),
                                                id: "apelido",
                                                class: "w-full",
                                                placeholder: _ctx.$t('placeholder.enterNickname')
                                            }, null, 8, ["modelValue", "placeholder"])
                                        ]),
                                        _: 1
                                    }, 8, ["label", "tooltip"]),
                                    _createVNode(UiFormControl, {
                                        label: _ctx.$t('input.email'),
                                        prop: "email",
                                        class: "col-span-full md:col-span-4"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(UiInput, {
                                                modelValue: form.email,
                                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((form.email) = $event)),
                                                modelModifiers: { trim: true },
                                                id: "email",
                                                disabled: "",
                                                placeholder: _ctx.$t('placeholder.email'),
                                                class: "w-full"
                                            }, null, 8, ["modelValue", "placeholder"])
                                        ]),
                                        _: 1
                                    }, 8, ["label"]),
                                    _createVNode(UiFormControl, {
                                        label: _ctx.$t('input.document'),
                                        class: "col-span-full md:col-span-4"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(UiInput, {
                                                modelValue: form.cpf,
                                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((form.cpf) = $event)),
                                                disabled: "",
                                                placeholder: _ctx.$t('placeholder.enterDocument'),
                                                mask: form.estrangeira ? '' : '###.###.###-##',
                                                class: "w-full"
                                            }, null, 8, ["modelValue", "placeholder", "mask"])
                                        ]),
                                        _: 1
                                    }, 8, ["label"]),
                                    _createVNode(UiFormControl, {
                                        label: _ctx.$t('input.phone'),
                                        class: "col-span-full md:col-span-4 lg:col-span-2"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(UiInputPhone, {
                                                class: "w-full",
                                                modelValue: form.telefone,
                                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((form.telefone) = $event))
                                            }, null, 8, ["modelValue"])
                                        ]),
                                        _: 1
                                    }, 8, ["label"]),
                                    _createVNode(UiFormControl, {
                                        label: _ctx.$t('input.birthDate'),
                                        prop: "dataDeNascimento",
                                        class: "relative col-span-full md:col-span-4 lg:col-span-2"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(UiDatePicker, {
                                                modelValue: form.dataDeNascimento,
                                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event) => ((form.dataDeNascimento) = $event)),
                                                id: "dataDeNascimento",
                                                "max-date": "today",
                                                class: "w-full"
                                            }, null, 8, ["modelValue"]),
                                            _createVNode(_Transition, {
                                                appear: "",
                                                "enter-active-class": "transition duration-500 ease-in-out",
                                                "enter-from-class": "opacity-0 scale-50 -translate-y-20"
                                            }, {
                                                default: _withCtx(() => [
                                                    (birthday.value)
                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, "🎂"))
                                                        : _createCommentVNode("", true)
                                                ]),
                                                _: 1
                                            })
                                        ]),
                                        _: 1
                                    }, 8, ["label"]),
                                    _createVNode(UiFormControl, {
                                        label: _ctx.$t('input.gender'),
                                        class: "col-span-full md:col-span-2"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(UiSelect, {
                                                placeholder: _ctx.$t('placeholder.select', { field: _ctx.$t('input.gender') }),
                                                class: "w-full",
                                                modelValue: form.genero,
                                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event) => ((form.genero) = $event)),
                                                data: _unref(generosList)
                                            }, null, 8, ["placeholder", "modelValue", "data"])
                                        ]),
                                        _: 1
                                    }, 8, ["label"]),
                                    _createVNode(UiFormControl, {
                                        label: _ctx.$t('labels.preferLanguage'),
                                        class: "col-span-full md:col-span-2"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(UiSelect, {
                                                placeholder: _ctx.$t('placeholder.select', { field: _ctx.$t('labels.preferLanguage') }),
                                                class: "w-full",
                                                modelValue: form.idiomaPreferido,
                                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event) => ((form.idiomaPreferido) = $event)),
                                                data: _unref(idiomasList)
                                            }, null, 8, ["placeholder", "modelValue", "data"])
                                        ]),
                                        _: 1
                                    }, 8, ["label"]),
                                    _createVNode(UiFormControl, {
                                        label: _ctx.$t('labels.skill', 2),
                                        class: "col-span-full md:col-span-4",
                                        tooltip: _ctx.$t('tooltips.profile.developSkills')
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(UiSelect, {
                                                modelValue: form.usuariosCompetencias,
                                                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event) => ((form.usuariosCompetencias) = $event)),
                                                searchable: "",
                                                data: _unref(competenciasStore).list.filter(el => el.active).map(el => ({ value: el.id, label: el.nome })),
                                                placeholder: _ctx.$t('placeholder.select', { field: _ctx.$t('labels.skill', 2) }),
                                                multiple: ""
                                            }, null, 8, ["modelValue", "data", "placeholder"])
                                        ]),
                                        _: 1
                                    }, 8, ["label", "tooltip"])
                                ]))
                                : _createCommentVNode("", true),
                            (tabsSelected.value == tabs[1].id)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                    (form.unidadeDeNegocioId)
                                        ? (_openBlock(), _createBlock(UiFormControl, {
                                            key: 0,
                                            label: _ctx.$t('labels.unit'),
                                            class: "col-span-3"
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(UiInput, {
                                                    modelValue: form.unidadeDeNegocioNome,
                                                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event) => ((form.unidadeDeNegocioNome) = $event)),
                                                    type: "text",
                                                    placeholder: "",
                                                    class: "w-full",
                                                    disabled: ""
                                                }, null, 8, ["modelValue"])
                                            ]),
                                            _: 1
                                        }, 8, ["label"]))
                                        : _createCommentVNode("", true),
                                    (form.departamentoId)
                                        ? (_openBlock(), _createBlock(UiFormControl, {
                                            key: 1,
                                            label: _ctx.$t('labels.department'),
                                            class: "col-span-3"
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(UiInput, {
                                                    modelValue: form.departamentoNome,
                                                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event) => ((form.departamentoNome) = $event)),
                                                    type: "text",
                                                    placeholder: "",
                                                    class: "w-full",
                                                    disabled: ""
                                                }, null, 8, ["modelValue"])
                                            ]),
                                            _: 1
                                        }, 8, ["label"]))
                                        : _createCommentVNode("", true),
                                    (form.cargoId)
                                        ? (_openBlock(), _createBlock(UiFormControl, {
                                            key: 2,
                                            label: _ctx.$t('labels.jobTitle'),
                                            class: "col-span-3"
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(UiInput, {
                                                    modelValue: form.cargoNome,
                                                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event) => ((form.cargoNome) = $event)),
                                                    type: "text",
                                                    placeholder: "",
                                                    class: "w-full",
                                                    disabled: ""
                                                }, null, 8, ["modelValue"])
                                            ]),
                                            _: 1
                                        }, 8, ["label"]))
                                        : _createCommentVNode("", true),
                                    (form.superiorDireto)
                                        ? (_openBlock(), _createBlock(UiFormControl, {
                                            key: 3,
                                            label: _ctx.$t('labels.supervisor'),
                                            class: "col-span-3"
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(UiInput, {
                                                    modelValue: form.superiorDiretoNome,
                                                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event) => ((form.superiorDiretoNome) = $event)),
                                                    type: "text",
                                                    placeholder: "",
                                                    class: "w-full",
                                                    disabled: ""
                                                }, null, 8, ["modelValue"])
                                            ]),
                                            _: 1
                                        }, 8, ["label"]))
                                        : _createCommentVNode("", true),
                                    (form.dataDeAdmissao)
                                        ? (_openBlock(), _createBlock(UiFormControl, {
                                            key: 4,
                                            label: _ctx.$t('input.admissionDate'),
                                            class: "col-span-3"
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(UiInput, {
                                                    modelValue: form.dataDeAdmissao,
                                                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event) => ((form.dataDeAdmissao) = $event)),
                                                    type: "date",
                                                    placeholder: "",
                                                    class: "w-full",
                                                    disabled: ""
                                                }, null, 8, ["modelValue"])
                                            ]),
                                            _: 1
                                        }, 8, ["label"]))
                                        : _createCommentVNode("", true)
                                ]))
                                : _createCommentVNode("", true),
                            (tabsSelected.value == tabs[2].id)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                    _createVNode(UiFormControl, {
                                        label: _ctx.$t('labels.educationLevel'),
                                        class: "col-span-3"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(UiSelect, {
                                                modelValue: form.escolaridade,
                                                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event) => ((form.escolaridade) = $event)),
                                                searchable: "",
                                                data: _unref(escolaridadeList),
                                                placeholder: _ctx.$t('placeholder.select', { field: _ctx.$t('labels.educationLevel') }),
                                                filterable: ""
                                            }, null, 8, ["modelValue", "data", "placeholder"])
                                        ]),
                                        _: 1
                                    }, 8, ["label"])
                                ]))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    }, 8, ["form-data", "rules"])
                ]),
                _cache[18] || (_cache[18] = _createElementVNode("div", {
                    id: "profile",
                    class: "absolute mx-auto left-0 right-0 w-4"
                }, null, -1))
            ]));
        };
    }
});
