import { reactive } from 'vue';
import { createGlobalState } from '@vueuse/core';
import { paramsService } from '@/services/parametros.services';
export const useParams = createGlobalState(() => {
    const logoFiles = {
        icon: null,
        menu: null,
        loginLight: null,
        loginDark: null
    };
    const originalParams = {
        banners: {
            active: true,
            items: []
        },
        general: {},
        logos: {
            menu: '',
            loginLight: '',
            loginDark: '',
            icon: ''
        },
        login: {
            align: 'right',
            carousels: []
        },
        colors: {
            images: {
                primary: {
                    light: '#c191ff',
                    dark: '#c191ff',
                    useDark: false
                },
                secondary: {
                    useCustom: false,
                    default: '#a3a3a3',
                    light: '#3cdfad',
                    dark: '#3cdfad',
                    useDark: false
                }
            },
            interface: {
                primary: '#c191ff',
                textColor: '#ffffff',
                autoFontColor: true
            }
        }
    };
    const batchMode = reactive({
        active: false,
        settings: {
            banners: false,
            logos: false,
            colors: false,
            login: false
        },
        parameters: { ...originalParams }
    });
    return {
        batchMode,
        resetParams: () => {
            Object.assign(batchMode.parameters, originalParams);
        },
        changedParams: () => {
            return JSON.stringify(batchMode.parameters) !== JSON.stringify(originalParams);
        },
        logos: {
            setFile: (type, file) => {
                logoFiles[type] = file;
            },
            removeFile: (type) => {
                logoFiles[type] = null;
            },
            clearFiles: () => {
                Object.keys(logoFiles).forEach((key) => {
                    logoFiles[key] = null;
                });
            },
            upload() {
                const uploadsStatus = [];
                // Verifica a necessidade de upload de Logos
                Object.keys(logoFiles).forEach((key) => {
                    if (!logoFiles[key])
                        return;
                    uploadsStatus.push(paramsService.uploadFile(logoFiles[key])
                        .then(({ data: url }) => {
                        batchMode.parameters.logos[key] = url;
                    }));
                });
                return Promise.all(uploadsStatus);
            }
        }
    };
});
