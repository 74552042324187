import { computed, reactive } from 'vue';
import { defineStore } from 'pinia';
import notificationHub from '@/services/notifications.service';
import { useAuthStore } from '@/stores/authStore';
export const useUserNotificationsStore = defineStore('userNotifications', () => {
    const authStore = useAuthStore();
    const state = reactive({
        status: 0,
        lastUpdate: null,
        list: []
    });
    const status = computed(() => {
        return {
            0: 'initial',
            1: 'loading',
            2: 'loaded',
            3: 'error'
        }[state.status] || 'unknown';
    });
    const unreadCount = computed(() => {
        return state.list.filter((x) => x.status === 'SENT').length;
    });
    const reset = () => {
        state.status = 0;
        state.lastUpdate = null;
        state.list.splice(0, state.list.length);
    };
    const update = async ({ page, size } = {}) => {
        if (!authStore.persistentInfo.id)
            return;
        state.status = 1;
        try {
            const { data } = await notificationHub.getPushListPlantar({
                page: page || 1,
                size: size || 10000,
                userId: authStore.persistentInfo.id
            });
            if (data?.length > 0) {
                reset();
                data.forEach((item) => {
                    const index = state.list.findIndex((x) => x.id === item.id);
                    if (index === -1)
                        state.list.push(item);
                    else
                        state.list[index] = item;
                });
                state.list.sort((a, b) => b.deliveryTime > a.deliveryTime ? 1 : -1);
            }
            state.status = 2;
        }
        catch (error) {
            //
            state.status = 3;
        }
    };
    const markAsRead = async (id) => {
        try {
            const { success, data } = await notificationHub.markAsRead({ id });
            if (success) {
                const index = state.list.findIndex((x) => x.id === id);
                if (index !== -1)
                    state.list.splice(index, 1, data);
            }
        }
        catch (error) {
            //
        }
    };
    const remove = async (id) => {
        try {
            const { success } = await notificationHub.deletePush({ id });
            if (success)
                update();
        }
        catch (error) {
            //
        }
    };
    return {
        state,
        status,
        unreadCount,
        reset,
        update,
        markAsRead,
        remove
    };
});
