import BaseService from '@/services/base.service';
import baseApi from './base.api';
import { dataURLtoFile } from '@/common/util';
import authStore from '@/stores/authStore';
import { useParams } from '@/composables/useParams';
export const paramsService = {
    ...baseApi,
    resource: 'parametro',
    async uploadFile(data, fileName) {
        const formData = new FormData();
        formData.append('arquivo', typeof data === 'string' ? dataURLtoFile(data, fileName) : data);
        return paramsService.post(`AdicionarArquivo`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
    },
    batchReplication: (contractIds) => {
        const { batchMode } = useParams();
        // Verifica a necessidade de upload de banners
        if (batchMode.settings.banners) {
            batchMode.parameters.banners.items.forEach((item) => {
                if (item.url.startsWith('data:image')) {
                    item.url = '';
                }
            });
        }
        return paramsService.put('BatchReplication', {
            contractIds,
            parameters: batchMode.parameters,
            replicationSpecificParameter: batchMode.settings
        });
    }
};
class ParametrosService extends BaseService {
    constructor() {
        super({ resource: 'parametro' });
    }
    async update(data) {
        return this.plantarApi.put(this.resource, {
            ...data,
            tenantId: authStore().getActiveTentant
        });
    }
    async dataUrlUpload(data, fileName) {
        const formData = new FormData();
        formData.append('arquivo', typeof data === 'string' ? dataURLtoFile(data, fileName) : data);
        return await this.plantarApi.post(`${this.resource}/AdicionarArquivo`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
    }
    async deleteImg(url) {
        return await this.plantarApi.delete(`${this.resource}/RemoverArquivo/${url}`);
    }
    async clientSettings(opt) {
        const { subDomain, clientUrl } = opt;
        const postData = { subDomain };
        if (clientUrl)
            postData.clientUrl = clientUrl;
        const { success, data } = await this.plantarApi.post(`open/parametros`, postData, {
            headers: {
                'X-Tenant': window?.configuration?.plantar?.tenant
            }
        });
        return {
            success,
            data
        };
    }
}
const parametrosService = new ParametrosService();
export default parametrosService;
